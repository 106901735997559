<script setup lang="ts">
import { ref, watch } from 'vue'
import DictCompCommonApi from '../../api/DictCompCommonApi'

const props = defineProps({ dictType: { type: String, default: '' } })
const v = defineModel<String>()
const options = ref()

async function initData() {
  const cacheKey = 'DC_DICT_SELECT_CACHE'
  if (!window[cacheKey]) {
    window[cacheKey] = DictCompCommonApi.dictData()
  }

  window[cacheKey].then(resp => {
    options.value = resp.data.items.filter(e => e.dictTypeFlag === props.dictType)
  })
}

// 监听 dictType 的变化，重新初始化数据
watch(() => props.dictType, () => {
  initData()
}, { immediate: true })

// 监听 v 的变化
watch(v, (newValue) => {
  console.log('v has changed:', newValue)
}, { deep: true }) // 确保监听深层次变化

</script>

<template>
  <el-select
    v-model="v"
    class="min-w-[180px]"
  >
    <el-option
      v-for="item in options"
      :key="item.dictValue"
      :label="item.name"
      :value="item.dictValue as string"
    />
  </el-select>
</template>

<style scoped>

</style>
