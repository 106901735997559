<script setup lang="ts">
import { Picture as IconPicture } from '@element-plus/icons-vue'

import { computed } from 'vue'

const props = defineProps({
  btnType: {
    type: String,
    default: 'primary'
  },
  btnText: {
    type: String,
    default: '请选择文件'
  },
  modelValue: {
    type: String,
    default: ''
  },
  width:{
    type: String,
    default: '178px'
  },
  height:{
    type: String,
    default: '178px'
  }
})


const imageUrl = computed(()=>{
  if(props.modelValue){
    return '/api/nasServer/access/' + props.modelValue
  }
  return ""
})
</script>

<template>
  <el-image  :src="imageUrl" :style="{width:width,height:height}" fit="cover" class="avatar">
    <template v-if="!imageUrl" #error>
      <div class="image-slot">
        <el-icon ><icon-picture /></el-icon>
      </div>
    </template>
  </el-image>
</template>

<style scoped>

 .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 30px;
}
.image-slot .el-icon {
  font-size: 24px;
}


</style>
