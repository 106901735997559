<script setup lang="ts">
import AmountText from '@/components/AmountText.vue'
import DcSinglePicShow from 'dc-comp-sdk/src/components/form/DcSinglePicShow.vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'

const v = defineModel([])

function qsParse(qsString) {
  return DcUtils.qsParse(qsString)
}
</script>

<template>
  <div class="w-full">
    <div
      v-for="(item, index) in v"
      :key="index"
      class="flex border mb-[12px] pt-0 pb-0 justify-between"
    >
      <div class="flex items-center border-r">
        <DcSinglePicShow width="66px" height="66px" :model-value="item.pic"></DcSinglePicShow>
      </div>
      <div class="flex flex-col flex-1 pl-[12px]">
        <div>
          <el-text size="small" class="!mr-[12px]">{{ item.name }}</el-text>
        </div>
        <div>
          <el-text size="small" class="!mr-[12px]">编号:{{ item.productNum }}</el-text>
          <el-text size="small" class="!mr-[12px]">数量:{{ item.itemQty }}</el-text>
          <el-text size="small" class="!mr-[12px]">分类:{{ item.categoryName }}</el-text>
          <el-text size="small" class="!mr-[12px]"
            >规格:
            <template v-for="(ic, index) in qsParse(item.skuCombo)" :key="index">
              <span class="mr-[12px]">
                <el-text size="small">{{ ic.name }} : {{ ic.value }}</el-text>
              </span>
            </template>
            <template v-if="!qsParse(item.skuCombo).length">
              <el-text size="small">常规</el-text>
            </template>
          </el-text>
          <el-text v-if="item.extraPrice > 0" type="danger" size="small" class="!mr-[12px]"
            >加价: <amount-text size="small" :amount="item.extraPrice"></amount-text>
          </el-text>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
