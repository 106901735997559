<template>
  <el-container class="dc-layout h-full">
    <el-aside
      class="dc-aside bg-[#191a23] z-50"
      :class="{ '!w-[64px]': isCollapse, '!w-[256px]': !isCollapse }"
    >
      <el-scrollbar>
        <DcAsideContent :logo-url="logoUrl" :small-logo-url="smallLogoUrl" :is-collapse="isCollapse" :menu="config.menuConfig"></DcAsideContent>
      </el-scrollbar>
    </el-aside>
    <el-container>
      <el-header class="dc-header flex items-center h-[64px] !p-0">
        <DcHeader
          :user-name="userName"
          @on-more-click="moreDrawer=!moreDrawer"
          @on-header-trigger-click="isCollapse = !isCollapse"
          :is-collapse="isCollapse"
          :user-dropdown-config="config.userDropdownConfig"
          :lang-dropdown-config="config.langDropdownConfig"
        >
          <template v-slot:header-left>
              <slot name="header-left"></slot>
          </template>
        </DcHeader>
      </el-header>
      <el-main class="!flex !flex-col !justify-between max-h-full !p-0">
        <DcMainContent>
          <slot></slot>
        </DcMainContent>
      </el-main>
    </el-container>
    <el-drawer size="360" v-model="moreDrawer" direction="rtl">

    </el-drawer>
  </el-container>
</template>

<script lang="ts" setup>
import LayoutConfig from '../../types/models/layout/LayoutConfig'
import { ref } from 'vue'
import DcAsideContent from './DcAsideContent.vue'
import DcHeader from './DcHeader.vue'
import DcMainContent from './DcMainContent.vue'

defineProps(
  {
    userName:{
      type:String,
      default:'DevCude'
    },
    logoUrl:{
      type:String,
      default:''
    },
    smallLogoUrl:{
      type:String,
      default:''
    },
    config: LayoutConfig
  }
)
const isCollapse = ref(false)
const moreDrawer = ref(false)
</script>

<style>
.dc-layout {
  @apply bg-[#f5f7f9];
}

.dc-layout .el-menu-item.is-active {
  @apply bg-[#2d8cf0];
}

.dc-layout .el-menu-item-group {
  @apply !bg-[#101117];
}

.dc-layout .el-sub-menu.el-menu {
  @apply !bg-[#101117];
}

.dc-layout .el-menu--inline {
  @apply !bg-[#101117];
}

.dc-layout .el-menu-item:hover {
  background-color: none;
  color: #fff;
}

.dc-layout .el-sub-menu__title:hover {
  background-color: none;
  color: #fff;
}

.dc-header {
  @apply bg-[#ffffff];
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

.dc-aside {
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
}

.dc-layout-header-trigger:hover {
  @apply bg-[#f8f8f9] cursor-pointer;
}

.dc-layout .el-breadcrumb__inner,
.dc-layout .el-breadcrumb__inner a,
.dc-layout .el-breadcrumb__separator {
  color: rgb(153, 153, 153);
  font-weight: 400;
  font-size: 14px;
}

.dc-layout .el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: #515a6e;
  font-weight: 400;
  font-size: 14px;
}

.dc-layout .el-dropdown,
.el-dropdown * {
  outline: none;
}
</style>
