<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue'

import ShpDeliveryOrderPrintTpl from '@/components/ShpDeliveryOrderPrintTpl.vue'
import printJs from 'print-js'
import qs from 'qs'

import $api from '../config/api'
import $http from 'devcude-common/src/utils/DcHttp'
import ShpStickerPrintTpl from '@/components/ShpStickerPrintTpl.vue'

const v = ref(false)
const loading = ref(false)
const emit = defineEmits(['finish'])
const print = ref(false)
const printItems = ref([])
const printTime = new Date()
async function handleClose() {
  printItems.value = []
}

let baseItems = [];

async function show(items) {
  console.log(items)
  baseItems = items;
  printItems.value = JSON.parse(JSON.stringify(items))
  console.log(printItems)
  v.value = true
}

function handlePrint() {
  print.value = true

  let key = 'shp_delivery_sticker_print_' + new Date().getTime()
  sessionStorage.setItem(key, JSON.stringify(baseItems))
  window.open('/shp-delivery-sticker-print-view?key=' + key)
  return;

  // nextTick(() => {
  //   printJs({
  //     printable: 'print-ctx',
  //     type: 'html',
  //     // header:"二维码打印",
  //     targetStyles: ['*'],
  //     repeatTableHeader: true,
  //     // style: '@page {margin:2.4cm 2cm ;resolution: 300dpi;}',
  //     //解决字体样式失效的问题
  //     font_size: '',
  //     onLoadingStart: () => {
  //       console.log('onLoadingStart')
  //     },
  //     onLoadingEnd: async () => {
  //       console.log('onLoadingEnd')
  //
  //       let ids = printItems.value.map((e) => e.id)
  //       await $http.post(
  //         $api.shpDeliveryOrderRef.updatePrintTimes,
  //         qs.stringify({ ids: ids.join(',') })
  //       )
  //       v.value = false
  //       emit('finish')
  //     },
  //     onPrintDialogClose: () => {
  //       print.value = false
  //     }
  //   })
  // })
}

const items = computed(() => {
  let data = []
  printItems.value.forEach((printItem) => {
    printItem.shpOrderParam.orderItems.forEach((orderItem) => {
      if (orderItem.prodType == 'PKG') {
        const itemPkgItems = []
        for (let i = 0; i < orderItem.itemQty; i++) {
          orderItem.pkgMustItems.forEach((pkg) => {
            pkg.diyName = `${pkg.name}`
            let skuComboNameMap = qs.parse(pkg.skuCombo)

            let skuItems = []
            for (let key in skuComboNameMap) {
              if(key && skuComboNameMap[key]){
                skuItems.push(key + '@' + (skuComboNameMap[key] ?  skuComboNameMap[key] : 'N/A'))
              }else{
                skuItems.push('主規格')
              }
            }
            pkg.skuCombo = skuItems.join(";")
            itemPkgItems.push(
              Object.assign(pkg, { orderInfo: printItem.shpOrderParam, pkgInfo: orderItem })
            )
          })

          orderItem.pkgCustItems.forEach((pkgGroup) => {
            pkgGroup.items.forEach((pkg) => {
              if (pkg.choosed) {
                pkg.diyName = `(自選)${pkg.name}`
                let skuComboNameMap = qs.parse(pkg.skuCombo)

                let skuItems = []
                for (let key in skuComboNameMap) {
                  if(key && skuComboNameMap[key]){
                    skuItems.push(key + '@' + (skuComboNameMap[key] ?  skuComboNameMap[key] : 'N/A'))
                  }else{
                    skuItems.push('主規格')
                  }
                }
                pkg.skuCombo = skuItems.join(";")
                itemPkgItems.push(
                  Object.assign(pkg, { orderInfo: printItem.shpOrderParam, pkgInfo: orderItem })
                )
              }
            })
          })
        }

        itemPkgItems.forEach((pkgItem, index) => {
          pkgItem.itemCount = itemPkgItems.length
          pkgItem.currentIndex = index + 1
        })
        data.push(...itemPkgItems)
      } else {

        if (!orderItem.skuCombo.endsWith('MAIN')) {
          let skuComboNameMap = qs.parse(orderItem.skuCombo)

          let skuItems = []
          for (let key in skuComboNameMap) {
            if(key) skuItems.push(key + '@' + (skuComboNameMap[key] ?  skuComboNameMap[key] : 'N/A'))
          }
          orderItem.skuCombo = skuItems.join(";")
          console.log(skuComboNameMap)
          let itemQty = orderItem.itemQty;
          if(!orderItem.skuRefProdItemQty || orderItem.skuRefProdItemQty==1){
            data.push(
              Object.assign({},orderItem, {
                orderInfo: printItem.shpOrderParam,
                itemQty: orderItem.itemQty
              })
            )
          }else{
            for (let i = 0; i < itemQty; i++) {

              data.push(
                Object.assign({},orderItem, {
                  orderInfo: printItem.shpOrderParam,
                  skuCombo:'',
                  itemQty: orderItem.skuRefProdItemQty ? orderItem.skuRefProdItemQty : orderItem.itemQty
                })
              )
            }
          }

        } else {
          orderItem.skuCombo = '';
          data.push(
            Object.assign(orderItem, {
              orderInfo: printItem.shpOrderParam
            })
          )
        }
      }
    })
  })
  data.forEach((e, i) => {
    e.print_i = i + 1
    e.print_sum = data.length
    e.split_i = 1
    e.split_o = 1
  })
  return data
})
defineExpose({ show })
</script>

<template>
  <el-dialog v-model="v" @close="handleClose" width="800px">
    <div>打印贴纸总数:{{ printItems.length }}</div>
    <el-divider></el-divider>
    <el-scrollbar v-loading="loading" height="800px">
      <div id="print-ctx" style="width: 100%">
        <template v-for="(item, i) in items" :key="i">
          <div class="break-after-page">
            <ShpStickerPrintTpl :data="item"></ShpStickerPrintTpl>
            <el-divider
              v-if="!print"
              class="print-line"
              border-style="dashed"
              content-position="center"
            ></el-divider>
          </div>
        </template>
      </div>
    </el-scrollbar>
    <el-divider></el-divider>
    <div class="flex justify-center">
      <el-button size="large" @click="handlePrint()" type="primary">打印</el-button>
      <el-button size="large" @click="v = false">取消</el-button>
    </div>
  </el-dialog>
</template>

<style></style>
