<script setup lang="ts">
import { computed, ref, toRef, watch } from 'vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import DcDateFormat from 'dc-comp-sdk/src/components/form/DcDateFormat.vue'

const props = defineProps(['data', 'tpl', 'logo'])
const printTime = ref(new Date())

function qsParse(qsString) {
  return DcUtils.qsParse(qsString)
}

const items = computed(() => {
  let group = {}
  props.data.items.forEach((item) => {
    // 获取当前 item 的分组键
    const key = item.shpSupplier

    // 如果分组键不存在于 acc 中，则添加一个空数组
    if (!group[key]) {
      group[key] = []
    }

    // 将 item 添加到相应的分组数组中
    group[key].push(item)
  })
  return group
}, {})
</script>

<template>
  <div :class="tpl">
    <div id="shp-delivery-order-pint-tpl" class="shp-delivery-order-pint-tpl p-[20px] bg-[#ffffff]">
      <div>
        <div class="card-title">采购信息</div>
        <table class="w-full">
          <tr>
            <td class="td-hd r-label">标题</td>
            <td>{{ data.name }}</td>
            <td class="td-hd r-label">采购日期</td>
            <td>{{ data.purchaseDate }}</td>
          </tr>
          <tr>
            <td class="td-hd r-label">打印时间</td>
            <td>
              <DcDateFormat v-model="printTime" format="YYYY-MM-DD HH:mm"></DcDateFormat>
            </td>
            <td class="td-hd r-label">商品数量</td>
            <td>{{ data.items.length }}</td>
          </tr>
        </table>
      </div>

      <!--      <div class="mt-[20px]">-->
      <!--        <div class="card-title">商品明细</div>-->
      <!--        <table class="w-full">-->
      <!--          <thead class="th-bg text-[#ffffff]" style="page-break-inside: avoid">-->
      <!--            <tr>-->
      <!--              <td>产品名称</td>-->
      <!--              <td>规格</td>-->
      <!--              <td>数量</td>-->
      <!--              <td>单位</td>-->
      <!--              <td>供应商</td>-->
      <!--              <td>生成时间</td>-->
      <!--            </tr>-->
      <!--          </thead>-->
      <!--          <tbody>-->
      <!--            <tr v-for="(item, i) in data.items" :key="i" style="page-break-inside: avoid">-->
      <!--              <td>{{ item.name }}</td>-->
      <!--              <td>-->
      <!--                <template v-for="(item, i) in qsParse(item.skuCombo)" :key="i">-->
      <!--                  <span class="mr-[12px]"> {{ item.name }} : {{ item.value }} </span>-->
      <!--                </template>-->
      <!--                <template v-if="!qsParse(item.skuCombo).length"> 主规格</template>-->
      <!--              </td>-->
      <!--              <td>{{item.qty}}</td>-->
      <!--              <td>{{item.unit}}</td>-->
      <!--              <td>{{item.shpSupplier}}</td>-->
      <!--              <td>-->
      <!--                <DcDateFormat v-model="item.createTime" format="YYYY-MM-DD HH:mm"></DcDateFormat>-->
      <!--              </td>-->
      <!--            </tr>-->
      <!--          </tbody>-->
      <!--        </table>-->
      <!--      </div>-->

      <div v-for="key in Object.keys(items)" :key="key">
        <div class="mt-[12px]">{{ key }}</div>
        <div v-for="(item,i) in items[key]" :key="item.id" class="mt-[6px]">
          <span>{{i +1}}.{{ item.name }}</span>
          <span v-for="i in 5" :key="i">&nbsp;</span>

          <span>*</span>
          <span>
            規格：<template v-for="(item, i) in qsParse(item.skuCombo)" :key="i">{{ item.name }} : {{ item.value }}</template><template v-if="!qsParse(item.skuCombo).length">主规格</template>
          </span>
          <span v-for="i in 5" :key="i">&nbsp;</span>
          <span>*</span>
          <span>{{item.qty}}({{item.unit}})</span>
        </div>
        <br>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rrs {
  $theme: rrs;
  @import '@/assets/print-tpl';
}

.jsh {
  $theme: jsh;
  @import '@/assets/print-tpl';
}

.lb {
  $theme: lb;
  @import '@/assets/print-tpl';
}

.tl {
  $theme: tl;
  @import '@/assets/print-tpl';
}

.sy {
  $theme: sy;
  @import '@/assets/print-tpl';
}

.ttt {
  $theme: ttt;
  @import '@/assets/print-tpl';
}
</style>
